@import '@styles/globals';

.general-icon {
    &--primaryColor {
        color: var(--gov-color-blue);
    }

    &--size-16 {
        font-size: rem-calc(16);
    }

    &--size-20 {
        font-size: rem-calc(20);
    }

    &--size-24 {
        font-size: rem-calc(24);
    }

    &--size-tile, &--size-32 {
        font-size: rem-calc(32);
    }
}

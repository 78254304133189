@import '@styles/globals';

.gov-footer {
    &__row--inversed {
        color: var(--gov-color-white);
    }

    &__icon {
        margin-right: 10px;
    }

    &__note {
        color: var(--gov-color-white);
        margin-top: rem-calc(5);

        &--large {
            font-size: rem-calc(20);
        }
    }

    &__link {
        &--external, &[target="_blank"] {

            &:after {
                max-height: 14px;
                display: inline-flex;
                align-items: center;
                position: absolute;
            }
        }
    }
}

@import '@styles/globals';

.gov-table {
    table-layout: fixed !important;

    &-cover {
        overflow-x: auto !important;
    }

    &__head-cell {
        vertical-align: top;
    }

    &__cell {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__head-row-controls,
    &__row-controls {
        width: 70px;
    }

    @include mq($from: tablet) {
        a {
            margin-left: 2px;
        }
    }

    @include mq($until: tablet) {
        td {
            max-width: 100% !important;
            width: auto !important;
        }
        
        a:active {
            margin-left: 2px;
        }
    }
}

.gov-sortable-table {
    &__trigger {
        line-height: inherit !important;
        display: flex;
        align-items: center;
        min-height: 1.5rem;
        padding: 0;
        padding-right: rem-calc(10);

        &:hover {
            text-decoration: none;
            background-color: unset;
        }

        div {
            padding-right: 0.5rem;

            &:hover {
                text-decoration: underline;
            }
        }

        &::after {
            line-height: 1;
            display: none;
        }

        &--asc, &--dsc {
            &::after {
                display: inline-block;
                position: relative;
                top: unset;
                right: unset;
            }
        }
    }
}
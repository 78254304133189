.whisperer-input {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__container {
        flex: 1 1 auto;
    }

    &__items {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;

        &--is-collapsed {
            display: none;
            visibility: hidden;
        }
    }

    &__item {
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
    }
}

.compatibility-test {
    &__table {
        display: block;
        width: 100%;
        overflow-x: auto;

        tr {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        th {
            display: grid;
            align-content: center;
            justify-content: start;
            margin-bottom: 0;
        }
    }

    &__table th {
        max-width: 60ch;
    }

    &__sub-row-header {
        padding-left: 32px;
    }

    &__crypto {
        th {
            display: table-cell;
            vertical-align: top;
        }

        td {
            grid-column: 2/4;
        }
    }
    &__sub-row-message {
        margin-left: 32px;
    }
}
@import '@styles/globals';

.gov-breadcrumbs {
    &__arrow {
        &:before {
            content: "e";
            display: inline-block;
            position: relative;
            color: var(--gov-color-grey-dark);
            font-family: "gov-basic-icons";
            font-size: rem-calc(24);
            text-transform: none;
            vertical-align: middle;
            opacity: .5;
        }
    }

    &__item {
        &::before {
            content: '' !important;
            display: none !important;
        }


        .gov-link {
            color: var(--gov-color-blue) !important;
        }

        .gov-link, .gov-title {
            display: inline-block;
            max-width: 60ch;
            overflow: hidden;
            min-width: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 100%;
            vertical-align: middle;
            margin: 0;
        }
    }
}

@import '@styles/globals';
@import './responsibilityBreakpoints';

/*  SHOW elements below breakpoint
------------------------------------------------------------------ */

$displays: block, flex;

@each $display in $displays {
    .u-display-#{$display} {
        display: $display !important;
        visibility: visible !important;
    }
}

$breakpoints: portable, tablet-landscape, tablet, mobile-landscape, mobile, palm;

@each $breakpoint in $breakpoints {
    .u-hide--#{$breakpoint} {
        @include mq($until: $breakpoint) {
            display: none !important;
            visibility: hidden !important;
        }
    }

    .u-hide--from-#{$breakpoint} {
        @include mq($from: $breakpoint) {
            display: none !important;
            visibility: hidden !important;
        }
    }
}

$class: '.gov-form-control';

#{$class} {
    page-break-inside: avoid;

    &:not(#{$class}--custom) {
        display: flex;
        flex-direction: column-reverse;

        #{$class}__label {
            position: relative;

            margin-bottom: rem-calc(10);
        }
    }

    &--custom {
        #{$class}__indicator {
            display: none;
        }

        #{$class}__label {
            padding-left: 0;
        }
    }

    &__datepicker:after {
        display: none;
    }

    .gov-select:after {
        display: none;
    }
}

.trainings {
    padding: 15px 0;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    &__empty-message {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .bold-dot {
        font-weight: bold;
    }

    &__training {
        width: 100%;
    }

    &__content {
        margin: 10px 0px 25px 0px;
    }
}

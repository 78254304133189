.gov-container {
    display: block;
    overflow: hidden;
    padding: rem-calc(40 20);
    width: 100%;

    &__content {
        display: block;
        margin: 0 auto;
        max-width: $gov-container-width;
    }

    &--no-y-offset {
        padding: rem-calc(0 20)
    }

    &--as-page {
        padding: rem-calc(100 20);

        p, .gov-paragraph {
            max-width: 580px;
        }
    }

    &--branded {
        padding-bottom: rem-calc(220);

        .gov-container__content {
            position: relative;

            &:after {
                @include pseudo(block, absolute, url($path + 'assets/images/_global/watermarks/_gov-blue.svg' + '?v=' + $version));
                top: rem-calc(-20); right: 0;

                width: 586px;

                opacity: 0.05;
                transform: translateX(30%);
            }
        }
    }

    /* RESPONSE
    ------------------------------------------------------------------ */
    @include mq($until: tablet) {
        &:not(.gov-container--no-y-offset):not(.gov-container--as-page):not(.gov-container--branded):not(.gov-footer) {
            padding: rem-calc(20);
        }

        &--branded {
            .gov-container__content {
                &:after {
                    top: 60%;

                    width: 428px;

                    transform: translateX(20%);
                }
            }
        }
    }

    @include mq($until: mobile) {
        &--as-page {
            padding: rem-calc(60 20);
        }

        &--branded {
            padding-bottom: rem-calc(120);
        }
    }
}

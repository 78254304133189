.gov-perex {
    margin: 0;
    margin-bottom: rem-calc(25);

    color: gov-colors('grey-dark');
    font-family: $gov-font-family-primary;
    font-size: rem-calc(20);
    font-weight: 400;
    letter-spacing: em-calc(0.2);
    line-height: 1.2;

    -webkit-font-smoothing: antialiased;

    a, .gov-link {
        font-size: rem-calc(20);
    }
}

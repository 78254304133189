@import '@styles/globals';

$component: '.gov-accordion';

#{$component} {
    &__header {
        word-break: break-word;
    }

    &__header-title {
        flex-grow: 1;
    }

    &__content {
        visibility: hidden !important;
        overflow: hidden !important;
        min-height: 0;
        height: 0;

        &--is-hidding {
            transition: none !important;
        }

        &--is-visible {
            height: auto !important;
            visibility: visible !important;
            overflow: initial !important;
        }
    }

    &--modal-padding {
        #{$component}__header:first-child {
            border-top: none;
        }

        #{$component}__header,
        #{$component}__content {
            padding-left: rem-calc(50);

            @include mq($until: tablet) {
                padding-left: rem-calc(30);
            }

            @include mq($until: mobile) {
                padding-left: rem-calc(20);
            }
        }
    }

    &--no-inner-x-offset {
        & > #{$component}__content > #{$component}__content-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.gov-autocomplete {
    position: relative;

    display: block;

    &__results {
        @extend .gov-list--plain;

        position: absolute;
        left: 0; right: 0; bottom: rem-calc(-25);
        z-index: 10;

        max-height: 320px;
        overflow-y: scroll;

        background-color: gov-colors('white');
        border-radius: $gov-border-radius;
        box-shadow: 0 0 $gov-outline-width 0 gov-colors('grey-light');

        transform: translateY(100%);
    }

    &__result {
        @extend .gov-title;
        @extend .gov-title--delta;

        display: block;
        margin: 0 !important;
        padding: rem-calc(15 10);

        cursor: pointer;

        @include hover() {
            background-color: gov-colors('blue-hover');
        }

        &.selected,
        &.is-active {
            background-color: gov-colors('blue-hover');
        }
    }

    &__empty {
        @extend .gov-title;
        @extend .gov-title--delta;

        display: block;
        margin: 0 !important;
        padding: rem-calc(15 10);

        color: gov-colors('grey-light');
    }
}

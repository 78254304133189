.gov-modal {
    position: fixed;
    left: 0; right: 0; top: 0; bottom: 0;
    @include z-index('modal', 1);

    display: none;
    align-items: center;
    justify-content: center;
    padding: rem-calc(20);

    &__header {
        padding: rem-calc(30 70 0 50);

        border-bottom: 1px solid gov-colors('grey-inactive');
    }

    &__content {
        position: relative;
        z-index: 2;

        max-width: $gov-container-width * 0.75;
        width: 90vw;

        background-color: gov-colors('white');
        border-radius: $gov-border-radius;

        opacity: 0;
        transform: translateY(rem-calc(20));
        transition: opacity 256ms 0ms ease-out, transform 256ms 0ms ease-out;
        will-change: opacity, transform;

        img {
            margin-bottom: rem-calc(25);
        }
    }

    &__content-inner {
        overflow-y: scroll;
        padding: rem-calc(30 50);
        max-height: 80vh;

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    // @TODO - Prepracovat až bude existovat button-icon
    &__close {
        position: absolute;
        right: rem-calc(20); top: rem-calc(28);

        width: rem-calc(38);
        height: rem-calc(38);

        padding: 0;

        &:before {
            @include pseudo(inline-block, relative, '\6a');

            font-family: $gov-icon-font;
            font-size: rem-calc(40);
            text-transform: none;

            color: gov-colors('grey-light');
        }

        &:hover {
            &:before {
                color: gov-colors('grey-dark');
            }
        }
    }

    &__backdrop {
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;

        background-color: gov-colors('grey-dark');

        opacity: 0;
        transition: opacity 128ms 0ms ease-out;
        will-change: opacity;
    }

    &.is-active {
        display: flex;
    }

    &.is-visible {
        .gov-modal__backdrop {
            opacity: 0.45;
            transition: opacity 128ms 128ms ease-out;
        }

        .gov-modal__content {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 512ms 128ms ease-out, transform 256ms 128ms ease-out;
        }
    }

    @include mq($until: tablet) {
        &__header {
            padding: rem-calc(20 50 0 30);
        }

        &__content-inner {
            padding: rem-calc(30);
        }

        &__close {
            top: rem-calc(20);
        }
    }

    @include mq($until: mobile) {
        padding: rem-calc(10);

        &__header {
            padding: rem-calc(20 50 0 20);
        }

        &__content {
            width: 100vw;
        }

        &__content-inner {
            padding: rem-calc(20);
        }

        &__close {
            right: rem-calc(10);
            top: rem-calc(16)
        }
    }
}

.shortcuts {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            position: absolute;

            a {
                height: 1px;
                width: 1px;
                overflow: hidden;
                position: absolute;

                &:focus {
                    position: relative;
                    background-color: var(--gov-color-white);
                    padding: 10px;
                    width: auto;
                    height: auto;
                    overflow: visible;
                    z-index: 1;
                }
            }
        }
    }
}

@import '@styles/globals';

.login-card {
    margin-bottom: 0;

    @include mq($until: mobile) {
        padding-right: rem-calc(30) !important;
    }

    @include mq($until: tablet) {
        margin-bottom: rem-calc(25);
    }
}

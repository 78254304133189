@import '@styles/globals';

.custom-form {
    position: relative;
    grid-column: 1 / -1;

    .gov-grid {
        margin-bottom: 0;

        @include mq($until: tablet) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .form-desc {
        margin-bottom: rem-calc(15);
    }

    &__rule {
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(15);

        .general-icon {
            margin-right: rem-calc(5);
        }

        &--valid {
            color: var(--gov-color-success);
        }

        &--optional {
            margin-left: rem-calc(25);
        }
    }
}

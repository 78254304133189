@import '@styles/globals';

.gov-header {
    &__link {
        & .is-active {
            &:before {
                transform: scale(1, 1);
            }
        }
    }

    &__item {
        &--login {
            @media screen and (min-width: em-calc(900)) {
                display: flex;
                gap: 25px;
            }
        }
    }
}
.file-value {
    display: flex;
    align-items: center;
    gap: 4px;

    &__file {
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0 !important;

        &::after {
            display: none !important;
        }
    }

    &__file-signature {
        flex: 0 0 auto;
    }
}
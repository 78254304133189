.manuals {
    position: relative;
    padding: 15px 0;
}

.manual {
    margin-bottom: 20px;

    &__header {
        font-size: 1rem;
        text-transform: unset;
        font-weight: unset;
    }

    &__description {
        line-height: 1.6;
    }
}
﻿.gov-badge--success {
    background-color: var(--gov-color-success);
    color: var(--gov-color-white);
}

.gov-badge--inversed {
    background-color: var(--gov-color-grey-dark);
    color: var(--gov-color-white);
}

.gov-badge--warning {
    background-color: var(--gov-color-yellow);
    color: var(--gov-color-grey-dark);
}

.gov-badge--warning a, .gov-badge--warning .gov-link, .gov-badge--warning .gov-fileinput__attachments > li > span, .gov-badge--warning .gov-portal-nav__link, .gov-badge--warning .gov-sortable-table__trigger {
    color: var(--gov-color-grey-dark);
}

.gov-badge--error {
    background-color: var(--gov-color-error);
    color: var(--gov-color-white);
}

.gov-badge + .gov-badge {
    margin-left: 0.25rem;
}

.gov-badge--space-right {
    margin-right: 8px;
}

.gov-badge--space-left {
    margin-left: 8px;
}

.gov-badge--no-space {
    margin-left: 0;
    margin-right: 0;
}

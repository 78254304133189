.gov-grid-tile {
    position: relative;

    display: block;
    padding: rem-calc(20 0);

    &:not(:last-of-type):before {
        @include pseudo();

        background-color: gov-colors('grey-inactive');
    }

    &:before {
        left: 0; right: 0; bottom: 0;

        height: 1px;
    }

    button, .gov-button, a, .gov-link {
        &:not(:only-of-type) {
            &:first-of-type {
                margin-right: rem-calc(10);
            }
        }

        @include mq($until: mobile-landscape) {
            & + button,
            & + .gov-button,
            & + a,
            & + .gov-link {
                display: table;
                margin-top: rem-calc(10);
            }
        }
    }

    //  RESPONSE
    @include mq($from: palm) {
        padding: rem-calc(20);
        padding-bottom: rem-calc(25);

        &:after {
            @include pseudo();
            right: 0; top: 0; bottom: 0;

            width: 1px;

            background-color: gov-colors('grey-inactive');
        }
    }

    @include mq($from: portable) {
        padding: rem-calc(20 40);
        padding-bottom: rem-calc(25);
    }
}

//  --------------------
a.gov-grid-tile {
    text-decoration: none;

    transition: background-color 256ms ease-out;
    will-change: background-color;

    .gov-preview-tile {
        &__headline {
            position: relative;

            display: block;
            padding-right: rem-calc(26);

            color: gov-colors('blue');

            &:after {
                @include pseudo(inline-block, absolute, '\65');
                right: 0; top: -1px;

                font-family: $gov-icon-font;
                font-size: rem-calc(24);
                text-transform: none;
            }
        }

        //  RESPONSE
        @include mq($from: portable) {
            &__headline {
                width: calc(100% + 20px);
            }
        }
    }

    @include hover() {
        background-color: gov-colors('white');

        .gov-preview-tile {
            &__icon,
            &__headline {
                color: gov-colors('grey-dark');
            }
        }
    }
}

blockquote, .gov-blockquote {
    &--warning {
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;

        &:before {
            background-color: var(--gov-color-yellow-dark);
        }

        &:after {
            content: 'F';
            color: var(--gov-color-yellow-dark);
        }
    }

    &--error {
        margin-bottom: 0.6rem;

        &:before {
            background-color: var(--gov-color-error);
        }

        &:after {
            content: 'F';
            color: var(--gov-color-error);
        }
    }
}

@import '@styles/globals';

.filter-manager {
    margin: 16px 0;

    &__head {
        display: flex;

        @include mq($until: tablet) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__search-input {
        flex: 1 1 auto;
        width: 100%;
    }

    &__advanced--hidden {
        display: none;
    }

    &__advanced-toggle {
        margin-left: 16px;
        white-space: nowrap;
        align-self: center;
        flex: 0 0 auto;

        @include mq($until: tablet) {
            margin-left: 0;
            width: 50%;
        }
    }

    &__buttons {
        display: flex;
        gap: rem-calc(15);

        > div {
            display: inline-block;
        }

        @include mq($until: tablet) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0;

            > div {
                width: 50%;
                display: flex;

                > button {
                    flex: 1;
                }
            }
        }
    }
}

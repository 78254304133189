.gov-grid-tile {
    &--fill-grid {
        grid-column: 1 / -1;
    }

    &--raw-text {
        p {
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }
}

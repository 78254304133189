@import '@styles/globals';

.login-form-frame {
    margin: 0 rem-calc(-8);

    &__iframe {
        border: none;
        width: 100%;
        min-height: rem-calc(30);
    }
}

//  MARGIN
//  margin-top
.u-mt--unset {
    margin-top: 0 !important;
}

//  margin-bottom
.u-mb--unset {
    margin-bottom: 0 !important;
}

.u-mb--25 {
    margin-bottom: rem-calc(25);
}

//  PADDING
//  padding-top
.u-pt--unset {
    padding-top: 0 !important;
}

.u-pt--20 {
    padding-top: rem-calc(20);
}

.u-pt--40 {
    padding-top: rem-calc(40);
}

.u-pt--60 {
    padding-top: rem-calc(60);
}

//  padding-bottom
.u-pb--unset {
    padding-bottom: 0 !important;
}

.u-pb--20 {
    padding-bottom: rem-calc(20);
}

.u-pb--40 {
    padding-bottom: rem-calc(40);
}

.u-pb--60 {
    padding-bottom: rem-calc(60);
}

.gov-badge {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    position: relative;
    top: -0.125rem;
    font-weight: 700;

    &--light {
        background-color: var(--gov-color-grey-inactive);
        color: var(--gov-color-grey-dark);
    }

    &--dark {
        background-color: var(--gov-color-grey-dark);
        color: var(--gov-color-white);
    }
}

@import '@styles/globals';

.out-ver, .test-env {
    .gov-header {
        background-color: gov-colors('grey-mid');

        &__separator {
            border-bottom: 1px solid gov-colors('grey-light');
        }

        &__item--login {
            @media screen and (min-width: em-calc(900)) {
                display: flex;
                align-items: center;

                > p {
                    color: var(--gov-color-white);
                    margin: 0;

                    @include mq($until: tablet) {
                        display: none;
                    }
                }
            }
        }

        .gov-logo {
            align-items: flex-end;

            &__env-text {
                display: block;
                font-weight: 500;
                letter-spacing: normal;
                font-size: 0.875rem;
            }
        }
    }
}

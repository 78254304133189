@import '@styles/globals';

$component: '.gov-grid';

#{$component} {
    &--align-items-start {
        align-items: start;
    }

    &--align-items-center {
        align-items: center;
    }

    &--align-items-end {
        align-items: end;
    }

    &--lower-row-spaces {
        row-gap: 1rem;
    }

    @include mq($from: mobile) {
        &--x2-mobile {
            grid-template-columns: repeat(2, 1fr);

            //  last row
            .gov-grid-tile:nth-child(2n+1):nth-last-child(-n+2),
            .gov-grid-tile:nth-child(2n+1):nth-last-child(-n+2) ~ .gov-grid-tile {
                &:before {
                    display: none;
                }
            }

            //  first column
            .gov-grid-tile:nth-last-child(2n) {
                padding-left: rem-calc(20);
            }

            //  last column
            .gov-grid-tile:nth-child(2n) {
                padding-right: rem-calc(20);

                &:after {
                    display: none;
                }
            }
        }
    }
}
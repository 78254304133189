@import '@styles/globals';
@import './responsibilityBreakpoints';

@for $i from 0 through 15 {
    .u-order-#{$i} {
        order: $i;
    }
}

@each $breakpoint in $breakpoints {
    @for $i from 0 through 15 {
        @include mq($from: $breakpoint) {
            .u-order-#{$breakpoint}-#{$i} {
                order: $i;
            }
        }
    }
}

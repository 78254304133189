@mixin textoverflow() {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.gov-grid--reversed {
    .gov-grid-tile {

        &:hover {
            color: var(--gov-color-grey-dark);
            background-color: var(--gov-color-blue-inactive-3);
        }

        .gov-preview-tile {
            &__headline:after {
                content: unset;
            }

            &__headline, p {
                @include textoverflow();
            }
        }
    }
}

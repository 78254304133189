@import '@styles/globals';

.input-with-button {
    .gov-form-control {
        &__input,
        &__label {
            padding-right: rem-calc(44);

            &:focus {
                border-color: unset;
            }
        }
    }

    &--wide {
        .gov-form-control {
            &__input,
            &__label {
                padding-right: rem-calc(102);
            }
        }
    }

    &--error {
        .gov-form-control {
            &__input {
                color: var(--gov-color-error);
                border-color: var(--gov-color-error);
                box-shadow: 0 0 0 2px var(--gov-color-error);

                &::placeholder {
                    color: var(--gov-color-error);
                }
            }
        }
    }

    @include mq($until: tablet) {
        margin-bottom: rem-calc(15) !important;
    }
}

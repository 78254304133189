@import '@styles/globals';

.gov-multiselect {
    .gov-form-control {
        &__input,
        &__label {
            padding-right: rem-calc(42);
        }
    }

    &__tags {
        white-space: normal;
    }
}

@import '@styles/globals';

.gov-grid-tile {
    &--hide-external {
        margin-right: 0 !important;

        &::after {
            @include mq($until: palm) {
                display: none !important;
            }
            content: "" !important;
            display: block !important;
            position: absolute !important;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            background-color: var(--gov-color-grey-inactive);
        }

        .gov-preview-tile__headline::after {
            content: 'o' !important;
        }
    }
}

@import '@styles/globals';

.home {
    &__alerts {
        background-color: var(--gov-color-yellow);

        & > div {
            margin: 0 auto;
            max-width: 70rem;
        }

        > iframe {
            max-width: 70rem;
        }
    }

    &__informace__left {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .statistics {

        @include mq($until: tablet-landscape) {
            display: block;
        }

        &__section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-inline: 8px;
            text-align: center;

            &__number {
                display: flex;
                align-items: center;

                h2 {
                    font-size: rem-calc(60);
                    margin-bottom: rem-calc(5);
                    font-weight: 300;
                }
            }
        }
    }

    &__logos {
        flex-wrap: wrap;

        img {
            object-fit: contain;
            height: 100px;
            flex: 1;
            margin: 5px 0 5px 0;
        }
    }
}

$class: '.gov-form-control';

#{$class} {
    &--custom {
        #{$class}__radio {
            position: absolute;
			z-index: -1;

            opacity: 0;

            & ~ #{$class}__indicator {
                border-radius: 50%;

                &:after {
                    @include pseudo();
                    left: 50%; top: 50%;

                    width: rem-calc(10);
                    height: rem-calc(10);
                    border-radius: rem-calc(10);

                    transform: translate(-50%, -50%);
                }
            }

            &:checked ~ #{$class}__indicator {
				&:after {
					background-color: gov-colors('blue');
				}
            }

            &:focus ~ #{$class}__indicator {
                box-shadow: 0 0 0 $gov-outline-width gov-colors('blue-focus');
                outline: 0;
            }

            //  --------------------
            &[aria-disabled="true"],
            &:disabled,
            &[readonly] {
                ~ #{$class}__label {
                    color: gov-colors('grey-light');
                    cursor: not-allowed;
                }

                ~ #{$class}__indicator {
                    border-color: gov-colors('blue-inactive-1');
                }

                &:checked ~ #{$class}__indicator {
                    &:after {
                        background-color: gov-colors('blue-inactive-1');
                    }
                }
            }
        }


        /*  Error
        ------------------------------------------------------------------ */
        &#{$class}--error {
            #{$class}__radio {
                &:checked ~ #{$class}__indicator {
                    &:after {
                        background-color: gov-colors('error');
                    }
                }

                &:focus ~ #{$class}__indicator {
                    box-shadow: 0 0 0 $gov-outline-width gov-colors('error');
                }
            }
        }

        /*  Inversed
        ------------------------------------------------------------------ */
        &#{$class}--inversed {
            #{$class}__radio {
                &:checked ~ #{$class}__indicator {
                    &:after {
                        background-color: gov-colors('white');
                    }
                }

                &:focus ~ #{$class}__indicator {
                    box-shadow: 0 0 0 $gov-outline-width gov-colors('white');
                }
            }
        }
    }
}

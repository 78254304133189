@import '@styles/globals';

$class: '.gov-tabs';

#{$class} {
    &--display-flex {
        height: 100%;
        display: flex;
        flex-direction: column;

        #{$class}__content-holder {
            flex: 1;
        }

        #{$class}__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: 100%;
            padding-bottom: 0;
        }
    }

    &__links-holder {
        display: flex;

        @include mq($until: tablet) {
            display: block;
        }

        &--no-margin {
            margin-bottom: 0 !important;
        }

        .text-right {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            padding-bottom: 5px;

            @include mq($until: tablet) {
                justify-content: center;
                margin-top: 5px;
            }

            a.multiweb-link {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }
        }
    }
}

.gov-form-control {
    &--custom {
        line-height: initial;
    }

    &--hidden {
        display: none;
    }

    &:not(.gov-form-control--custom) {
        .gov-form-control__label {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.not-empty, &.default-behaviour-unset {
        .gov-form-control__label {
            background-color: transparent;
        }
    }
}

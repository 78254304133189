$class: '.gov-portal-subnav';

#{$class} {
    &__headline {
        @extend .gov-title;
        @extend .gov-title--alpha;

        display: block;
        padding-right: rem-calc(20);
        max-width: 250px;

        color: gov-colors('white');
    }

    &__menu,
    &__submenu {
        @extend .gov-list--plain;

        margin-bottom: 0;
        width: 100%;
    }

    &__menu {
        a, .gov-link {
            text-decoration: none;

            @include hover() {
                text-decoration: underline;
            }

            &.is-active {
                text-decoration: underline;
            }
        }
    }

    &__link {
        @extend .gov-link--inversed;

        font-size: 1.25em;
    }

    /*  DESKTOP layout
    ------------------------------------------------------------------ */
    @include mq($from: mobile-landscape) {
        position: absolute;
        left: 0; right: 0; bottom: rem-calc(-20);
        @include z-index('base', 50);

        display: flex;
        overflow: hidden;
        padding: rem-calc(40 50);
        min-height: 350px;

        background-color: gov-colors('blue');
        border-radius: $gov-border-radius;
        box-shadow: 0 1px 4px 0 gov-colors('grey-dark');
        pointer-events: none;
        visibility: hidden;

        opacity: 0;
        transform: translateY(100%);

        &:after {
            @include pseudo(block, absolute, url($path + 'assets/images/_global/watermarks/_gov-white.svg' + '?v=' + $version));
            top: rem-calc(6); right: 0;

            width: 462px;

            opacity: 0.1;
            transform: translateX(16%);
        }

        &__menu,
        &__submenu {
            padding-top: rem-calc(10);
            max-width: 250px;
        }

        &__menu {
            #{$class}__item {
                &:not(:last-of-type) {
                    margin-bottom: rem-calc(15);
                }
            }
        }

        &__submenu {
            position: relative;

            margin-bottom: rem-calc(25);
            padding-bottom: rem-calc(20);

            &:after {
                @include pseudo();
                left: 0; right: 0; bottom: -1px;

                height: 1px;

                background-color: gov-colors('blue-light');
            }
        }
    }

    /*  MOBILE layout
    ------------------------------------------------------------------ */
    @include mq($until: mobile-landscape) {
        position: relative;

        &:after {
            @include pseudo();
            left: rem-calc(20); right: rem-calc(20); bottom: 0;

            height: 1px;

            background-color: gov-colors('blue-light');
        }

        &__headline {
            display: none;
        }

        &__menu {
            #{$class}__item {
                margin-bottom: 0;
            }
        }

        &__submenu {
            margin-bottom: 0;

            li {
                margin-bottom: 0;
            }

            a, .gov-link  {
                display: block;
                margin-right: 0 !important;
                padding: rem-calc(20);
                padding-left: rem-calc(60);

                font-size: rem-calc(14);
                font-weight: 500;
                letter-spacing: normal;
                text-decoration: none !important;
                text-transform: uppercase;

                @include hover() {
                    background-color: gov-colors('white');
                    color: gov-colors('blue') !important;
                }

                &.is-active {
                    background-color: gov-colors('white');
                    color: gov-colors('blue') !important;
                }
            }
        }

        &__item {
            &.has-subnav {
                #{$class}__link {
                    padding-right: rem-calc(30);

                    &:after {
                        @include pseudo(inline-block, absolute, '\62');
                        right: rem-calc(15); top: 50%;

                        margin-top: rem-calc(-12);

                        color: currentColor;
                        font-family: $gov-icon-font;
                        font-size: rem-calc(24);
                        text-transform: none;
                        vertical-align: middle;
                    }
                }
            }
        }

        &__link {
            position: relative;

            display: block;
            padding: rem-calc(20);
            padding-left: rem-calc(40);

            font-size: rem-calc(14);
            font-weight: 500;
            letter-spacing: normal;
            text-decoration: none !important;
            text-transform: uppercase;

            @include hover() {
                background-color: gov-colors('white');
                color: gov-colors('blue') !important;
            }

            &.is-active {
                background-color: gov-colors('white');
                color: gov-colors('blue') !important;
            }
        }
    }
}

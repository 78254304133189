.filter-input {
    display: flex;
    white-space: nowrap;
    align-items: center;

    &--inline {
        display: inline-flex;
        flex: 1;
    }

    &__buttons {
        padding-right: 0.5rem;
        z-index: 5;
        display: inline-block;
        position: relative;
    }

    &__right-button {
        margin-left: 0.5rem;
    }
}

@import '@styles/globals';

.paginator {
    @include mq($until: tablet) {
        &__container {
            display: block;

            &__buttons {
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    margin-bottom: rem-calc(15);
                }
            }

            .gov-pagination {
                &__holder {
                    justify-content: space-between;
                }

                &__item {
                    margin: 0;
                    padding: 0;
                    padding-top: rem-calc(15);
                }
            }
        }
    }
}

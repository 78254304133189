.gov-flexbox {
    &--direction-column {
        flex-direction: column;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--justify-space-evenly {
        justify-content: space-evenly;
    }
    
    &--align-start {
        align-items: flex-start;
    }
}

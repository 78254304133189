@import '@styles/globals';

// primary
.color--blue {
    color: gov-colors('blue');
}

.color--grey-dark {
    color: gov-colors('grey-dark');
}

.color--white {
    color: gov-colors('white');
}

//  blue
.color--blue-dark {
    color: gov-colors('blue-dark');
}

.color--blue-focus {
    color: gov-colors('blue-focus');
}

.color--blue-light {
    color: gov-colors('blue-light');
}

.color--blue-inactive-1 {
    color: gov-colors('blue-inactive-1');
}

.color--blue-inactive-2 {
    color: gov-colors('blue-inactive-2');
}

.color--blue-inactive-3 {
    color: gov-colors('blue-inactive-3');
}

.color--blue-hover {
    color: gov-colors('blue-hover');
}

//  neutral
.color--grey-mid {
    color: gov-colors('grey-mid');
}

.color--grey-light {
    color: gov-colors('grey-light');
}

.color--grey-inactive {
    color: gov-colors('grey-inactive');
}

.color--grey-bg {
    color: gov-colors('grey-bg');
}

//  yellow
.color--yellow {
    color: gov-colors('yellow');
}

.color--yellow-dark {
    color: gov-colors('yellow-dark');
}

//  warning
.color--error {
    color: gov-colors('error');
}

.color--success {
    color: gov-colors('success');
}

//  rgb
.color--grey-dark-rgb {
    color: gov-colors('grey-dark-rgb');
}

.color--white-rgb {
    color: gov-colors('white-rgb');
}

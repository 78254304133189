.crypto-detail {
    &__caption {
        font-weight: bold;
        padding: 1.5rem 0.625rem;
    }

    &__download-button {
        margin-top: 8px;
    }
}
